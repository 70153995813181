@import '../styles/_inocrowd-variables';
@import '../styles/_mixins';

.site-navbar {
  user-select: none;
  background-color: $color-background-light;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  border-bottom: 1px solid $color-background-grayed;
  z-index: 100000;

  > .container,
  > .container > .row {
    height: 100%;
  }

  .logo {
    height: 20px;
  }

  .nav-menu-button,
  .i18n {
    display: none;
  }

  .submenu-title {
    &.open {
      img {
        @include transform(rotateX(180deg));
      }
    }

    img {
      pointer-events: none;
      @include transition(all 400ms ease-in-out);
    }
  }
}

@media (max-width: 61.999em) {
  .site-navbar {
    .nav-wrapper {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 100%;
      width: 100vw;
      background-color: $color-background-light;
      padding-top: 56px;
      padding-left: 1rem;
      padding-right: 1rem;
      @include transition(right 0.22s ease-in-out);
      z-index: -1;

      &:before {
        content: '';
        position: absolute;
        top: 56px;
        height: 1px;
        left: 0;
        right: 0;
        background-color: $color-background-grayed;
      }

      &.open {
        right: 0;
      }

      .nav-list {
        text-align: left;
        margin-top: 40px;

        .nav-item {
          &:not(:first-child) {
            margin-top: 20px;
          }

          a,
          .submenu-title {
            display: inline-block;
            position: relative;
            padding: 5px;
            left: -5px;
            font-size: 1rem;
            letter-spacing: 0.21px;
            line-height: 1;
            text-decoration: none;
            color: $color-text-dark;

            &.active,
            &.hasActiveChild {
              color: $color-theme-1;
            }
          }

          .submenu-title {
            cursor: pointer;

            img {
              vertical-align: middle;
              margin-left: 7px;
            }
          }

          .submenu {
            padding-left: 15px;
            padding-top: 10px;

            .nav-item {
              &:not(:first-child) {
                margin-top: 12px;
              }

              a {
                display: inline-block;
                position: relative;
                padding: 5px;
                left: -5px;
                font-size: 0.875rem;
                letter-spacing: 0.19px;
                line-height: 1;
                text-decoration: none;
                color: $color-text-grayed-light;
                z-index: 2;
                &.active {
                  color: $color-text-dark;
                }
              }
            }
          }
        }
      }
    }

    .nav-menu-button {
      margin: 0;
      padding: 0;
      border: none;
      background: none;
      display: block;
      width: 24px;
      height: 24px;
      @include transform(rotate(0deg));
      @include transition(all 0.5s ease-in-out);
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 50%;
        background: $color-text-dark;
        opacity: 1;
        @include transform(rotate(0deg));
        @include transition(all 0.25s ease-in-out);

        &:nth-child(even) {
          left: 50%;
          @include border-radius(0 20px 20px 0);
        }

        &:nth-child(odd) {
          left: 0;
          @include border-radius(20px 0 0 20px);
        }

        &:nth-child(1),
        &:nth-child(2) {
          top: 0;
        }

        &:nth-child(3),
        &:nth-child(4) {
          top: 8px;
        }

        &:nth-child(5),
        &:nth-child(6) {
          top: 16px;
        }
      }

      &.open {
        span {
          &:nth-child(1),
          &:nth-child(6) {
            @include transform(rotate(45deg));
          }

          &:nth-child(2),
          &:nth-child(5) {
            @include transform(rotate(-45deg));
          }

          &:nth-child(1) {
            left: 0;
            top: 5px;
          }

          &:nth-child(2) {
            left: calc(50% - 4px);
            top: 5px;
          }

          &:nth-child(3) {
            left: -50%;
            opacity: 0;
          }

          &:nth-child(4) {
            left: 100%;
            opacity: 0;
          }

          &:nth-child(5) {
            left: 0;
            top: 13px;
          }

          &:nth-child(6) {
            left: calc(50% - 4px);
            top: 13px;
          }
        }
      }
    }
  }
}

@media (min-width: 62em) {
  .site-navbar {
    height: 70px;

    .logo {
      height: 25px;
    }

    .nav-wrapper .nav-list {
      > .nav-item {
        display: inline-block;
        margin-right: 15px;
        position: relative;

        a,
        .submenu-title {
          font-size: 0.938rem;
          font-weight: 400;
          letter-spacing: 0.2px;
          line-height: 1.533;
          color: $color-text-grayed-light;
          text-decoration: none;
          vertical-align: middle;
          @include transition(all 0.3s ease-in-out);

          &.active,
          &.hasActiveChild {
            color: $color-theme-1;
          }
        }

        &:last-child a {
          padding: 8px 18px;
          color: $color-theme-2;
          border: 2px solid $color-theme-2;
          @include border-radius(4px);
          @include transition(all 500ms ease-in-out);
          background: transparent;

          &:hover {
            color: $color-text-light;
            background: $color-theme-2;
          }
        }

        &:not(:last-child) > a:after,
        &:not(:last-child) > .submenu-title:after {
          content: '';
          position: absolute;
          bottom: -24px;
          width: calc(100% + 20px);
          left: 50%;
          @include transform(translateX(-50%));
          height: 2px;
          background-color: transparent;
          @include transition(all 0.3s ease-in-out);
        }

        &:not(:last-child) > a:hover:after,
        &:not(:last-child) .submenu-title:hover:after,
        &:not(:last-child) .submenu-title.open:after {
          background-color: $color-text-grayed-light;
        }

        &:not(:last-child) > a.active:after,
        &:not(:last-child) .submenu-title.hasActiveChild:after {
          background-color: $color-theme-1;
        }

        .submenu-title {
          cursor: pointer;

          img {
            margin-left: 5px;
            vertical-align: middle;
          }
        }

        .collapse-wrapper {
          position: absolute;
          top: calc(100% + 25px);
          left: 50%;
          @include transform(translateX(-50%));

          .ReactCollapse--content {
            padding: 0 10px 10px 10px;
          }
        }

        .submenu-wrapper {
          padding-top: 15px;
        }

        .submenu {
          background-color: $color-background-light;
          padding: 10px 7px;
          min-width: 120px;
          width: -moz-max-content;
          width: max-content;
          text-align: left;
          @include box-shadow(0 2px 6px 0 rgba(192, 196, 215, 0.4));
          position: relative;

          &:before,
          &:after {
            content: '';
            position: absolute;
            top: -3px;
            left: 50%;
            width: 21px;
            height: 21px;
            @include transform(rotate(45deg) translateX(-50%));
          }

          &:before {
            @include box-shadow(0 2px 6px 0 rgba(192, 196, 215, 0.4));
            z-index: -1;
          }

          &:after {
            background: $color-background-light;
          }

          .nav-item {
            &:not(:last-child) {
              margin-bottom: 5px;
            }

            a {
              display: block;
              position: relative;
              z-index: 2;
              font-size: 0.938rem;
              font-weight: 400;
              letter-spacing: 0.1px;
              line-height: 1.533;
              color: $color-text-grayed;
              text-decoration: none;
              padding: 5px 13px;
              @include transition(all 0.3s ease-in-out);
              background-color: $color-background-light;
              @include border-radius(2px);

              &:hover {
                background-color: $color-background-grayed-light-45;
              }

              &.active {
                color: $color-text-grayed-dark;
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .i18n {
      display: block;
      position: relative;

      .select__control {
        border: none;
        background: none;
        min-height: auto;
        cursor: pointer;
        padding: 5px;

        &.select__control--is-focused {
          @include box-shadow(none);
        }

        .select__indicators {
          display: none;
        }

        .select__value-container {
          padding: 0;
          width: 30px;
          height: 30px;
          background-image: url('../assets/icons/ic-language.svg');
          background-position: center center;
          background-repeat: no-repeat;
          @include background-size(24px 24px);

          .select__single-value {
            display: none;
          }
        }
      }

      .select__menu {
        @include box-shadow(0 2px 6px 0 rgba(192, 196, 215, 0.2));
        background: $color-background-light;
        border: none;
        margin-top: 30px;
        width: 120px;
        left: 50%;
        @include transform(translateX(-50%));

        &:before {
          content: '';
          position: absolute;
          top: -3px;
          left: 50%;
          width: 21px;
          height: 21px;
          background: $color-background-light;
          @include transform(rotate(45deg) translateX(-50%));
          @include box-shadow(0 2px 6px 0 rgba(192, 196, 215, 0.2));
        }

        .select__menu-list {
          padding-left: 4px;
          padding-right: 4px;
          background: $color-background-light;
          text-align: left;

          .select__option {
            padding: 5px 20px 6px 14px;
            background-color: transparent;
            font-size: 0.938rem;
            font-weight: 400;
            line-height: 1.533;
            letter-spacing: 0.1px;
            color: $color-text-grayed;
            @include border-radius(2px);
            @include transition(all 0.3s ease-in-out);
            cursor: pointer;

            &:hover,
            &.select__option--is-focused {
              background-color: $color-background-grayed-light-45;
            }

            &.select__option--is-selected {
              font-weight: 600;
              color: $color-text-grayed-dark;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 72em) {
  .site-navbar .nav-wrapper .nav-list > .nav-item {
    margin-right: 40px;
  }
}
