@import 'styles/_inocrowd-variables';
@import 'styles/_mixins';
@import 'styles/_reset';
@import 'styles/_flexboxgrid';
@import 'styles/_animations';
@import url('https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i');

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  @include font-smoothing();
}

* {
  box-sizing: border-box;
}

.relative {
  position: relative;
}

.app-shell {
  background-color: $color-background-light;
  overflow: hidden;

  &.freeze {
    height: 100vh;
  }
}

.page-wrapper {
  padding-top: 56px;
  background-color: $color-background-light;
  overflow: hidden;
  min-height: calc(100vh - 745px);
}

.ellipsis-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (min-width: $sm-size) {
  .ellipsis-line-sm {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: $md-size) {
  .page-wrapper {
    padding-top: 70px;
    min-height: calc(100vh - 338px);
  }
}

.display-none {
  display: none;
}
