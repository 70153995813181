@import "../../styles/_inocrowd-variables.scss";
@import '../../styles/_mixins';

.not-found-wrapper {
  margin-top: 50px;
  text-align: center;
  padding-bottom: 100px;

  h1 {
    color: $color-text-grayed;
    font-size: 1.75rem;
    font-weight: 400;
    padding-top: 70px;
    padding-left: 58px;
  }

  img {
    width: 100%;
    height: auto;
  }

  div.center {
    padding: 30px 15px;
    text-align: center;

    span {
      color: $color-text-grayed;
      font-size: 1.75rem;
      font-weight: 400;
    }
  }

  a{
    display: inline-block;
    padding: 12px 25px;
    @include border-radius(4px);
    text-decoration: none;
    background-color: $color-theme-2;
    color: $color-text-light;
    @include transition(all 500ms);

    &:hover{
      opacity: 0.8;
    }

    span{
      font-size: 0.938rem;
      font-weight: 500;
      line-height: 1;
    }
  }
}
