@import '../styles/_inocrowd-variables';
@import '../styles/_mixins';

.site-footer {
  user-select: none;
  padding: 45px 0 0;
  border-top: 1px solid $color-background-grayed;

  .logo {
    width: auto;
    height: 28px;
  }

  .column-title {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: normal;
    color: $color-text-dark;
    margin-bottom: 6px;
  }

  .navigation {
    margin-top: 42px;
  }

  .addresses,
  .phones {
    margin-top: 28px;
  }

  .navigation-item a,
  .address-item p,
  .phone-item a {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: normal;
    text-decoration: none;
    color: $color-text-grayed;
    padding: 5px;
    position: relative;
    left: -5px;
    display: inline-block;
  }

  .navigation-item a,
  .phone-item a {
    @include transition(all 0.5s);

    &:hover {
      color: $color-text-dark;
    }
  }

  .address-item p {
    white-space: pre-line;
    line-height: 2.071;
  }

  .i18n {
    margin-top: 28px;
    margin-bottom: 4px;
    display: block;
    position: relative;
    width: 100%;

    .select__control {
      border: none;
      background: none;
      padding: 10px 10px 10px 25px;
      min-height: auto;
      cursor: pointer;

      &.select__control--is-focused {
        @include box-shadow(none);
      }

      .select__indicators {
        display: none;
      }

      .select__value-container {
        padding: 0;

        .select__single-value {
          margin: 0;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.25;
          letter-spacing: normal;
          color: $color-text-dark;
          overflow: visible;

          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: calc(100% + 7px);
            @include transform(translateY(-50%));
            z-index: 2;
            background-image: url('../assets/icons/ic-language.svg');
            pointer-events: none;
            width: 17px;
            height: 17px;
            background-position: center center;
            background-repeat: no-repeat;
            @include background-size(contain);
          }
        }
      }
    }

    .select__menu {
      @include box-shadow(0 2px 10px 0 rgba(192, 196, 215, 0.4));
      background: $color-background-light;
      border: none;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        top: -3px;
        left: 50%;
        width: 21px;
        height: 21px;
        background: $color-background-light;
        @include transform(rotate(45deg) translateX(-50%));
        @include box-shadow(0 2px 10px 0 rgba(192, 196, 215, 0.4));
      }

      .select__menu-list {
        padding-left: 4px;
        padding-right: 4px;
        background: $color-background-light;

        .select__option {
          padding: 5px 20px 6px 14px;
          background-color: transparent;
          font-size: 0.938rem;
          font-weight: 400;
          line-height: 1.533;
          letter-spacing: 0.1px;
          color: $color-text-grayed;
          @include border-radius(2px);
          @include transition(all 0.3s ease-in-out);
          cursor: pointer;

          &:hover,
          &.select__option--is-focused {
            background-color: $color-background-grayed-light-45;
          }

          &.select__option--is-selected {
            font-weight: 600;
            color: $color-text-grayed-dark;
          }
        }
      }
    }
  }

  .privacy-and-terms,
  .faqs {
    p,
    a {
      font-size: 0.875rem;
      line-height: 1.5;
      letter-spacing: normal;
      font-weight: 400;
      color: $color-text-grayed;
    }

    a {
      color: $color-text-dark;
      text-decoration: none;
    }
  }

  .faqs {
    margin-bottom: 15px;
  }

  .social-links {
    white-space: nowrap;

    .social-link-item {
      display: inline-block;

      &:not(:first-child) {
        margin-left: 10px;
      }

      a,
      img {
        display: block;
      }

      img {
        width: 28px;
        height: 28px;
        @include border-radius(100%);
        background-color: $color-text-dark;
        @include transition(all 0.5s);

        &:hover {
          &.facebook {
            background-color: #3b5998;
          }

          &.linkedin {
            background-color: #0177b5;
          }

          &.twitter {
            background-color: #09a0e9;
          }

          &.instagram {
            background-color: #d62976;
          }

          &.youtube {
            background-color: #e42d28;
          }
        }
      }
    }
  }

  hr {
    display: block;
    width: 100%;
    height: 21px;
    padding: 0;
    margin: auto;
    border: none;
    background: none;
  }

  .copyright {
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.429;
    color: $color-text-grayed;
  }

  .footer-illustration {
    float: right;
    margin-right: -2rem;
    margin-top: 40px;
  }

  .language-and-terms {
    text-align: right;
    float: right;
  }

  [class^='col-xs'],
  [class^='col-sm'],
  [class^='col-md'],
  [class^='col-lg'] {
    display: block;
  }
}

@media (min-width: 62em) {
  .site-footer {
    padding: 50px 0 0;
    position: relative;

    .navigation-item a,
    .phone-item a {
      line-height: 1.4;
    }

    .address-item p,
    .privacy-and-terms p,
    .faqs p {
      line-height: 2.071;
    }

    .i18n,
    .navigation,
    .addresses,
    .phones {
      margin-top: 0;
    }

    .i18n {
      margin-right: -10px;
      margin-top: -10px;
      margin-bottom: 0;
      margin-left: auto;

      .select__control {
        .select__value-container {
          @include justify-content(flex-end);
        }
      }
    }

    .faqs {
      margin-bottom: 9px;
    }

    hr {
      height: 30px;
    }

    .copyright {
      margin-bottom: 36px;
    }

    .footer-illustration {
      margin: 0;
      float: none;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

@media (max-width: 991px) {
  .site-footer {
    .language-and-terms {
      text-align: left;
      float: left;
    }
    .footer-illustration-sector {
      margin-top: -125px;
    }
    .gutterless {
      margin-top: 30px;
    }
    .navigation,
    .addresses {
      margin-top: 0;
    }
  }
}

@media (max-width: 491px) {
  .site-footer {
    .footer-illustration-sector {
      margin-top: 0;
    }
  }
}
